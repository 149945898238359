<template>
  <b-row>
    <b-col>
      <b-row class="mb-2">
      <b-col class="text-white">
        {{ $t('event_anmeldung.kid_search_instruction') }}
      </b-col>
      </b-row>
      <b-row class="mb-4">
      <b-col>
        <vue-typeahead-bootstrap
          v-model="searchText"
          size="sm"
          :showAllResults="true"
          :ieCloseFix="false"
          :data="kids"
          :serializer="item => `${item.firstName} ${item.lastName}`"
          @hit="kidSelected"
          :disabledValues="(selectedKid ? [selectedKid.login] : [])"
          :placeholder="$t('event_anmeldung.kid_search_placeholder')"
          @input="lookupKids"
        >
          
          <template slot="append">
            <b-spinner v-show="displayKidSearchLoader" small label="Suche..."></b-spinner>
          </template>

          <template slot="suggestion" slot-scope="{ data }">
            <b-row v-html="getSuggestionLayout(data)"></b-row>
          </template>
        </vue-typeahead-bootstrap>
      </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {debounce} from 'lodash';
import axios from "axios";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

export default {
  components: {
    VueTypeaheadBootstrap
  },
  data() {
    return {
      searchText: null,
      kids: [],
      selectedKid: null,
      displayKidSearchLoader: false,
    }
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  methods: {
    kidSelected(kid) {
      this.$emit('kidSelected', kid)
    },
    getSuggestionLayout(data) {
      var firstName = String(data.firstName);
      var lastName = String(data.lastName);
      var club = String(data.club);
      var ageGroup = String(data.ageGroup);
      var kidId = String(data.kidId);
      var memberNumberSat = String(data.memberNumberSat);
      var searchText = this.searchText;


      firstName = this.hightlightSearchTerm(firstName, searchText);
      lastName = this.hightlightSearchTerm(lastName, searchText);
      club = this.hightlightSearchTerm(club, searchText);
      ageGroup = this.hightlightSearchTerm(ageGroup, searchText);
      kidId = this.hightlightSearchTerm(kidId, searchText);
      memberNumberSat = this.hightlightSearchTerm(memberNumberSat, searchText);

      var html = '';
      html += '<div class="col">';
      html += `<span class="d-block">${firstName} ${lastName}</span>`;

      if (club) html += `<small class="d-inline-block">${club}</small>`;
      if (club && ageGroup) html += `<small class="d-inline-block">&nbsp;|&nbsp;</small>`;
      if (ageGroup) html += `<small class="d-inline-block">${this.$t('event_anmeldung.label_jahrgang')}: ${ageGroup}</small>`;

      html += '</div>';

      html += '<div class="col text-right">';
      html += `<span class="d-inline-block">ID: ${kidId}</span>`;

      if (memberNumberSat) html += `<span class="d-inline-block">&nbsp; / &nbsp;</span>`;
      if (memberNumberSat) html += `<span class="d-inline-block">${this.$t('event_anmeldung.label_lizenz')}: ${memberNumberSat}</span>`;

      html += '</div>';

      return html;
    },
    hightlightSearchTerm(term = '', search = '') {
      if (term === '' || term === null || term === undefined) return '';
      return term.replace(new RegExp(search, "gi"), match => {
          return '<span class="vbt-matched-text">' + match + '</span>';
      });
    },
    lookupKids: debounce(function(){
      this.displayKidSearchLoader = true;
      axios
          .get("/getKidsForRegistration.php", {
            params: {
              kidSearch: this.searchText,
              nwpType: this.event.nwpType
            }
          })
          .then((resp) => {
            var kids = resp.data;
            this.kids = kids.filter(kid => this.kidIsAllowed(kid));
          })
          .catch((err) => {
            console.log('err', err);
          })
          .then(() => {
            this.displayKidSearchLoader = false;
          });
    }, 500),
    getMaxAgeGroup() {
      var minAge = 0;
      var currentYear = new Date().getFullYear();
      var youngestCategory = this.event.youngestCategory || false;
      
      // Parse min. Alter aus dem LIC, falls keine lokale Ausscheidung
      if (youngestCategory) {
        minAge = parseInt(youngestCategory.match(/\d+/).shift());
      }

      // Hebe min. Alter auf, wenn Jüngste-Kategorie nicht M10/W10 ist
      if (minAge != 10) {
        minAge = 0;
      }

      return currentYear - minAge;
    },
    getMinAgeGroup() {
      var maxAge = 15; // Max. 15 Jahre ist vordefiniert
      var currentYear = new Date().getFullYear();

      return currentYear - maxAge;
    },
    kidIsAllowed(kid) {
      // Prüfe, ob alle notwendigen Felder vorhanden sind
      const allDataPresent = (kid.ageGroup && kid.cantonLic && kid.firstName && kid.lastName && kid.sexLic) ? true : false;

      // Prüfe Jahrgang des Kids
      const ageGroupIsCorrect = (kid.ageGroup >= this.getMinAgeGroup() && kid.ageGroup <= this.getMaxAgeGroup());

      // Prüfe Qualifikation (Jedes Kid ist per se qualifiziert)
      var isQualified = true;

      // Prüfe Qualifikation: Kantonalfinal (Qualifikation)
      if (this.event.nwpCategory == 'Lov.Sat.NWPCategory.MilleGruyereCantonFinal' && this.event.nwpEligible == 'Lov.Sat.NWPEligible.MilleGruyereCantonFinalQualificationRequired') {
        isQualified = kid.milleQualifiedRegionalFinal && this.event.nwpCanton == kid.qualificationCantonLic;
      }

      // Prüfe Qualifikation: Schweizer Final
      if (this.event.nwpCategory == 'Lov.Sat.NWPCategory.MilleGruyereSwissFinal') {
        isQualified = kid.milleQualifiedSwissFinal || kid.milleWildcard;
      }

      return allDataPresent && ageGroupIsCorrect && isQualified;
    }
  }
}
</script>

<style>
  .input-group-append {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
  }
</style>