<template>
  <b-row id="coachApplication">
    <b-col>
      <b-row>
        <b-col>
          <h2 class="h5">{{ pageTitle }}</h2>
        </b-col>
      </b-row>
      <b-row v-if="login.display">
        <b-col>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
            <b-form @submit.prevent="handleSubmit(getKids)">
              <b-row>
                <b-col sm="6">
                  <ValidationProvider :name="$t('event_anmeldung.label_lizenznummer')" rules="required"
                    v-slot="validationContext">
                    <b-form-group id="fieldset-satMemberNumber" :label="$t('event_anmeldung.label_lizenznummer')"
                      label-for="input-satMemberNumber">
                      <b-form-input id="input-satMemberNumber" v-model="login.form.satMemberNumber"
                        :state="getValidationState(validationContext)" aria-describedby="feedback-satMemberNumber"
                        size="sm"></b-form-input>
                      <b-form-invalid-feedback id="feedback-satMemberNumber">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="6">
                  <ValidationProvider :name="$t('event_anmeldung.label_passwort')" rules="required"
                    v-slot="validationContext">
                    <b-form-group id="fieldset-password" :label="$t('event_anmeldung.label_passwort')"
                      label-for="input-password">
                      <b-form-input id="input-password" v-model="login.form.password"
                        :state="getValidationState(validationContext)" aria-describedby="feedback-password" size="sm"
                        type="password"></b-form-input>
                      <b-form-invalid-feedback id="feedback-password">{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row v-if="login.formFeedback.display">
                <b-col>
                  <b-alert :variant="login.formFeedback.type" show>
                    {{ login.formFeedback.message }}
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button type="submit" variant="secondary" block>
                    {{ $t('event_anmeldung.label_einloggen') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
      <b-row id="kidsList" v-if="kidsList.display">
        <b-col sm="4" md="4" lg="2" class="ml-auto">
          <b-button type="button" @click="getExcel" block>
            Excel
          </b-button>
        </b-col>
        <b-table striped hover responsive :items="kidsList.kids" :fields="eventFields">
          <template #cell(isKidSelected)="row">
            <input type="checkbox" v-model="row.item.isKidSelected" @change="handleCheckboxChange(row.item)">
          </template>
          <template #cell(isSubscribed)="row">
            <input disabled type="checkbox" v-model="row.item.isSubscribed">
          </template>
        </b-table>
      </b-row>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(registerAthletes)">

          <b-row v-if="kidsList.subscribeKids.length > 0 && kidsList.display" class="mt-4">
            <b-col>
              <b-row>
                <b-col>
                  <h3 v-if="hasKidMissingData" class="h5">
                    {{ $t('event_anmeldung.label_fehlende_daten_ergänzen') }}
                  </h3>
                </b-col>
              </b-row>
              <b-row v-for="kid, index in kidsList.subscribeKids" :key="kid.kidId">
                <template v-if="!kidsList.subscribeKids[index].emailType">
                  <b-col>
                    {{ kid.lastName }} {{ kid.firstName }}
                  </b-col>
                  <b-col>
                    <b>{{ $t('event_anmeldung.label_email') }}:</b> {{ kid.email }}
                  </b-col>
                  <b-col>
                    <ValidationProvider :vid="`kidEmailType${index}`" :name="$t('event_anmeldung.label_email_type')"
                      :rules="{ required: true }" v-slot="validationContext">
                        <b-form-group label-class="font-weight-bold" label-cols="auto" label-size="md"
                          :label="$t('event_anmeldung.label_email_type') + ' *'" :label-for="`kidEmailType${index}`">
                          <b-form-select :id="`kidEmailType${index}`" size="sm"
                            v-model="kidsList.subscribeKids[index].emailType" :options="formSelect.emailType"
                            :state="getValidationState(validationContext)"
                            :aria-describedby="`feedback-kidEmailType-${index}`"></b-form-select>
                          <b-form-invalid-feedback :id="`feedback-kidEmailType-${index}`">{{ validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </template>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox id="input-agbAccepted" v-model="agb.accepted" :value="true" :unchecked-value="false"
                    class="mb-3"
                    @change="agb.accepted === true ? agb.error.display = false : agb.error.display = false">
                    <span v-html="$t('event_anmeldung.text_terms')"></span>
                    <small v-if="agb.error.display" class="d-block text-danger">{{ agb.error.message }}</small>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="kidsList.display && kidsList.kids.length > 0">
            <b-col sm="4" md="4" lg="2" class="ml-auto">
              <b-button type="submit" :disabled="kidsList.subscribeKids.length <= 0" block>{{
            $t('event_anmeldung.label_anmelden') }} <span v-if="kidsList.subscribeKids.length > 0">({{
            kidsList.subscribeKids.length }})</span></b-button>
              <b-button type="button" @click="unregisterAthletes" :disabled="kidsList.unsubscribeKids.length <= 0" style="background-color: red;" block>{{
            $t('event_anmeldung.label_abmelden') }} <span v-if="kidsList.unsubscribeKids.length > 0">({{
            kidsList.unsubscribeKids.length }})</span></b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <b-row v-if="results.display && kidsList.processedKids.length > 0">
        <b-col>
          <b-row v-for="kid in kidsList.processedKids" :key="kid.kidId">
            <b-col v-if="kid.response.success" class="text-success">
              {{ kid.lastName }} {{ kid.firstName }}: {{ kid.response.message }}
            </b-col>
            <b-col v-else class="text-danger">
              {{ kid.lastName }} {{ kid.firstName }}: {{ kid.response.message }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-if="results.display && kidsList.processedUnregisterKids.length > 0">
        <b-col>
          <b-row v-for="kid in kidsList.processedUnregisterKids" :key="kid.kidId">
            <b-col v-if="kid.success" class="text-success">
              {{ kid.lastName }} {{ kid.firstName }}: {{ kid.message }}
            </b-col>
            <b-col v-else class="text-danger">
              {{ kid.lastName }} {{ kid.firstName }}: {{ kid.message }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="results.display && (kidsList.processedUnregisterKids.length > 0 
      || kidsList.processedKids.length > 0)">
            <b-col>
              <b-button :to="`/${$i18n.locale}/veranstaltungen`" variant="primary">
                {{ $t('event_anmeldung.label_zurück_zur_veranstaltungssuche') }}
              </b-button>
            </b-col>
          </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      pageTitle: this.$t('event_anmeldung.label_anmelden_als_coach'),
      login: {
        display: true,
        form: {
          satMemberNumber: null,
          password: null
        },
        formFeedback: {
          display: false,
          type: '',
          message: ''
        }
      },
      kidsList: {
        display: false,
        kids: [],
        processedKids: [],
        processedUnregisterKids: [],
        subscribeKids: [],
        unsubscribeKids: [],
        kidsAllreadySubscibred: [],
      },
      agb: {
        accepted: false,
        error: {
          display: false,
          message: ''
        }
      },
      formSelect: {
        emailType: []
      },
      results: {
        display: false
      },
      specialAargauerKantonalfinalList: [ // kidId aller Athleten, die automatisch qualifiziert sind
        '1759019',
        '1759017',
        '1759033',
        '1759035',
        '1759054',
        '1759046',
        '1759049',
        '1759065',
        '1759069',
        '1759080',
        '1759081',
        '1759111',
        '1759112',
        '1759113',
        '1759130',
        '1759097',
        '1759146',
        '1759051',
        '1759095',
        '1759160',
        '1759161',
        '1759167',
        '1759168',
        '1759183',
        '1759197',
        '1759109',
        '1759181',
        '1759209',
        '1275754',
        '1657434',
        '1759216',
        '1759217'
      ],
      eventFields: [
        {
          key: "id",
          label: "",
          sortable: false,
        },
        {
          key: 'isKidSelected',
          label: '',
          //formatter: 'checkbox',
        },
        {
          key: "lastName",
          label: this.$t("event_anmeldung.label_nachname"),
          sortable: true,
        },
        {
          key: "firstName",
          label: this.$t("event_anmeldung.label_vorname"),
          sortable: true,
        },
        {
          key: "ageGroup",
          label: this.$t("event_anmeldung.label_jahrgang"),
          sortable: true,
        },
        {
          key: "kidCategory",
          //TODO Translsation
          label: 'Kategorie',
          sortable: true,
        },
        {
          key: "isSubscribed",
          label: this.$t("event_anmeldung.label_ist_angemeldet"),
          sortable: true,
        },
      ],
    }
  },
  props: {
    event: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.getEmailTypeList();
  },
  methods: {
    mapLov(arr) {
      return arr.map((obj) => {
        var rObj = { value: "", text: "" };
        rObj.value = obj.lic;
        rObj.text = this.$t(obj.lic);
        return rObj;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getEmailTypeList() {
      this.$store
        .dispatch("lov/getEmailType")
        .then((resp) => {
          this.formSelect.emailType = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getKids() {

      this.login.form.nwpId = this.event.id;
      this.$store
        .dispatch("coach/getRegisteredClubMembersForNWP", this.login.form)
        .then((resp) => {
          var response = resp.data;
          
          if (response.listDataParticipants) {
            this.kidsList.kidsAllreadySubscibred = response.listDataParticipants;
          }
        })
        .catch((err) => {
        })
        .then(() => {
          this.$store
            .dispatch("coach/getClubMembers", this.login.form)
            .then((resp) => {
              var response = resp.data;
              if (response.listDataParticipants) {
                let currentYear = new Date().getFullYear();
                this.kidsList.kids = response.listDataParticipants.filter(kid => this.kidIsAllowed(kid));
                this.kidsList.kids = this.kidsList.kids.map((kid, index) => {
                  kid.isKidSelected = false;
                  let genderShort = kid.sex == "Lov.Sat.Sex.Male" ? 'M' : 'W';
                  kid.kidCategory = genderShort + (currentYear - kid.ageGroup);
                  kid.isSubscribed = this.kidsList.kidsAllreadySubscibred.some(regKid => regKid.kidId == kid.kidId);

                  if (kid.emailSprint) {
                    kid.email = kid.emailSprint;
                    kid.emailType = kid.emailTypeSprint;
                    return kid;
                  }
                  return kid;
                });
                this.$emit('login');
                this.displayKidsList();
              }
            })
            .catch((err) => {
              if (err.response !== undefined && err.response.status === 401) {
                this.login.formFeedback.display = true;
                this.login.formFeedback.type = "danger";
                this.login.formFeedback.message = this.$t("event_registrierung.anmeldung.form_unauthorized");
              } else {
                console.log(err);
                this.login.formFeedback.display = true;
                this.login.formFeedback.type = "danger";
                this.login.formFeedback.message = this.$t("event_registrierung.anmeldung.form_fail");
              }
            })
            .then(() => {
              this.displayPageLoader = false;
            });
        });

    },
    getMaxAgeGroup() {
      var minAge = 0;
      var currentYear = new Date().getFullYear();
      var youngestCategory = this.event.youngestCategory || false;

      // Parse min. Alter aus dem LIC, falls keine lokale Ausscheidung
      if (youngestCategory) {
        minAge = parseInt(youngestCategory.match(/\d+/).shift());
      }

      // Hebe min. Alter auf, wenn Jüngste-Kategorie nicht M10/W10 ist
      if (minAge != 10) {
        minAge = 0;
      }

      return currentYear - minAge;
    },
    getMinAgeGroup() {
      var maxAge = 15; // Max. 15 Jahre ist vordefiniert
      var currentYear = new Date().getFullYear();

      return currentYear - maxAge;
    },
    kidIsAllowed(kid) {
      // Prüfe, ob alle notwendigen Felder vorhanden sind
      const allDataPresent = (kid.ageGroup && kid.canton && kid.firstName && kid.lastName && kid.sex && (kid.email ||kid.emailMille)) ? true : false;

      // Prüfe Jahrgang des Kids
      const ageGroupIsCorrect = (kid.ageGroup >= this.getMinAgeGroup() && kid.ageGroup <= this.getMaxAgeGroup());

      // Prüfe Qualifikation (Jedes Kid ist per se qualifiziert)
      var isQualified = true;

      // Prüfe Qualifikation: Kantonalfinal (Qualifikation)
      if (this.event.nwpCategory == 'Lov.Sat.NWPCategory.MilleGruyereCantonFinal' && this.event.nwpEligible == 'Lov.Sat.NWPEligible.MilleGruyereCantonFinalQualificationRequired') {
        isQualified = kid.milleQualifiedRegionalFinal && this.event.nwpCanton == kid.qualificationCanton;
      }

      // Prüfe Qualifikation: Schweizer Final
      if (this.event.nwpCategory == 'Lov.Sat.NWPCategory.MilleGruyereSwissFinal') {
        isQualified = kid.milleQualifiedSwissFinal || kid.milleWildcard;
      }

      return allDataPresent && ageGroupIsCorrect && isQualified;
    },
    displayLogin() {
      this.$helpers.scrollToTop();
      this.kidsList.display = false;
      this.login.display = true;
      this.results.display = false;
      this.pageTitle = this.$t('event_anmeldung.label_anmelden_als_coach');
    },
    displayKidsList() {
      this.$helpers.scrollToTop();
      this.kidsList.display = true;
      this.login.display = false;
      this.results.display = false;
      this.pageTitle = this.$t('event_anmeldung.label_athletinnen_und_athleten_anmelden');
    },
    displayResults() {
      //this.$helpers.scrollToTop();
      this.kidsList.display = true;
      this.login.display = false;
      this.results.display = true;
      this.pageTitle = this.$t('event_anmeldung.label_athletinnen_und_athleten_anmelden');
    },
    registerAthletes() {
      // Return, if no kid was selected
      if (this.kidsList.subscribeKids.length <= 0) return;

      // Prüfe ob Teilnahmebedingungen akzeptiert wurden (breche anmeldung ab, falls nicht)
      if (this.agb.accepted === false) {
        this.agb.error.message = this.$t('event_anmeldung.error_terms_not_accepted');
        this.agb.error.display = true;
        return;
      }

      this.kidsList.processedKids = [];
      var kidsList = this.kidsList.subscribeKids;
      for (let index = 0; index < kidsList.length; index++) {
        // Prepare request data
        //console.log('to register KidId: ', kidsList[index].kidId);
        var data = {
          nwpId: this.event.id, // Id des Wettkampfes
          firstName: String(kidsList[index].firstName).trim(), // Vorname
          lastName: String(kidsList[index].lastName).trim(), // Nachname
          ageGroup: kidsList[index].ageGroup, // Jahrgang
          satMemberNumber: kidsList[index].satMemberNumber, // Swiss Athletics Lizenznummer
          sex: kidsList[index].sex, // Geschlecht
          addressLine1: kidsList[index].addressLine1 ?? '', // Adresse
          zip: kidsList[index].zip ?? '', // PLZ
          city: kidsList[index].city ?? '', // Ort
          canton: kidsList[index].canton, // Kanton
          club: kidsList[index].club, // Verein
          email: kidsList[index].email, // E-Mail
          emailType: kidsList[index].emailType, // E-Mail Typ
          language: kidsList[index].language, // Sprache
          agbAccepted: false, // AGB & Teilnahmebedingungen wurden akzeptiert
          receivePartnerOffers: false, // Informationen über Partnerangebote sind erlaubt
        };

        // Sende formular daten an api
        this.$store.dispatch("wettkampf/setTeilnehmer", data)
          .then(() => {
            kidsList[index].response = {
              success: true,
              message: this.$t('event_anmeldung.text_anmeldung_erfolgreich')
            };
            this.kidsList.processedKids.push(kidsList[index]);
          })
          .catch((err) => {
            var responseMessage = '';

            var severity = '';
            var errorCode = '';
            var debugMessage = '';
            var userFriendlyMessage = '';
            var lic = '';
            var success = false;

            try {
              severity = err.response.data.severity;
              errorCode = err.response.data.errorCode;
              debugMessage = err.response.data.debugMessage;
              userFriendlyMessage = err.response.data.userFriendlyMessage;
              lic = err.response.data.lic;
            } catch (error) {
              severity = '';
              errorCode = '';
              debugMessage = '';
              userFriendlyMessage = '';
              lic = '';
            }

            if (errorCode == 'NAMESDONTMATCH' && userFriendlyMessage) {
              responseMessage = `${this.$t('event_anmeldung.error_license_not_matching')} - ${userFriendlyMessage} - ${this.$t('event_anmeldung.text_try_again_later')}`;
            } else if (errorCode == 'YEAROFBIRTHDONTMATCH' && userFriendlyMessage) {
              responseMessage = `${this.$t('event_anmeldung.error_license_not_matching')} - ${userFriendlyMessage} - ${this.$t('event_anmeldung.text_try_again_later')}`;
            } else if (errorCode == 'KIDALREADYREGISTEREDEVENT') {
              responseMessage = this.$t('event_anmeldung.error_kid_already_registered');
              success = true;
            } else if (debugMessage.includes('SatMemberNumber')) {
              responseMessage = `${this.$t('event_anmeldung.error_license_not_matching')} - ${this.$t('event_anmeldung.error_license_no_matching_person')}`;
            } else {
              // Do nothing
            }

            if (!responseMessage) {
              try {
                responseMessage = userFriendlyMessage ? userFriendlyMessage : `${this.$t('event_anmeldung.error_something_went_wrong')} - ${this.$t('event_anmeldung.text_try_again_later')}`;
              } catch (error) {
                responseMessage = `${this.$t('event_anmeldung.error_something_went_wrong')} - ${this.$t('event_anmeldung.text_try_again_later')}`;
              }
            }
            kidsList[index].response = {
              success: success,
              message: responseMessage,
            };
            this.kidsList.processedKids.push(kidsList[index]);
          })
          .then(() => {
            this.kidsList.subscribeKids = [];
            this.refreshKids();
          });
      }

      this.displayResults();

    },
    unregisterAthletes() {
      //console.log('event id: ', this.event.id);
      this.displayPageLoader = false;

      if (this.kidsList.unsubscribeKids.length <= 0) return;

      this.kidsList.processedUnregisterKids = [];
      this.kidsList.unsubscribeKids.forEach((kid) => {

        var data = {
          nwpId: String(this.event.id).trim(),
          kidId: kid.kidTechnicalId,
          satMemberNumber: this.login.form.satMemberNumber,
          password: this.login.form.password,
        };
        //console.log('Data for cancelReg: ', JSON.stringify(data));

        this.$store
        .dispatch("coach/cancelRegistration", data)
        .then((resp) => {
          var response = resp.data;
          console.log('cancelRegistration:  ', JSON.stringify(resp))
          var kidUnregistered = {
            success : true,
            lastName : kid.lastName,
            firstName : kid.firstName,
            message: this.$t('event_anmeldung.text_abmeldung_erfolgreich')
          }
          //console.log('kidUnregistered:  ', JSON.stringify(kidUnregistered))

          this.kidsList.processedUnregisterKids.push(kidUnregistered);
          //console.log('processedUnregisterKids:  ', JSON.stringify(this.kidsList.processedUnregisterKids))

        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.kidsList.unsubscribeKids = [];
          this.refreshKids();
          this.displayPageLoader = false;
        });
      });
    },
    getExcel() {
      try {
        this.displayPageLoader = true;
        var data = {
          nwpId: String(this.event.id).trim(),
          satMemberNumber: this.login.form.satMemberNumber,
          password: this.login.form.password,
        };

        this.$store
          .dispatch("coach/getEventRegistrationForNWPExcel", data)
          .then((resp) => {
            const link = document.createElement("a");
            link.href = "data:application/octet-stream;base64," + resp;
            link.setAttribute("download", "Anmeldungen.xlsx");
            link.click();
          })
          .catch((err) => {
            console.log(err);
          })
          .then(() => {
            this.displayPageLoader = false;
          });
      } catch (error) {
        this.displayPageLoader = false;
        console.log(error);
      }
    },
    getSubscribedKids() {
      //console.log('getSubscribedKids called');

    },
    handleCheckboxChange(item) {
      
      if (item.isSubscribed) {
        if (item.isKidSelected) {
          this.kidsList.unsubscribeKids.push(item);
        } else {
          const index = this.kidsList.unsubscribeKids.findIndex(kid => kid.id === item.id);
          if (index !== -1) {
            this.kidsList.unsubscribeKids.splice(index, 1);
          }
        }
      } else {
        if (item.isKidSelected) {
          this.kidsList.subscribeKids.push(item);
        } else {
          const index = this.kidsList.subscribeKids.findIndex(kid => kid.id === item.id);
          if (index !== -1) {
            this.kidsList.subscribeKids.splice(index, 1);
          }
        }
      }
    },
    refreshKids() {
      this.$store
        .dispatch("coach/getRegisteredClubMembersForNWP", this.login.form)
        .then((resp) => {
          var response = resp.data;
          
          if (response.listDataParticipants) {
            this.kidsList.kidsAllreadySubscibred = response.listDataParticipants;
          }
        })
        .catch((err) => {
        })
        .then(() => {
          this.$store
            .dispatch("coach/getClubMembers", this.login.form)
            .then((resp) => {
              var response = resp.data;
              if (response.listDataParticipants) {
                let currentYear = new Date().getFullYear();
                this.kidsList.kids = response.listDataParticipants.filter(kid => this.kidIsAllowed(kid));
                this.kidsList.kids = this.kidsList.kids.map((kid, index) => {
                  let genderShort = kid.sex == "Lov.Sat.Sex.Male" ? 'M' : 'W';
                  kid.kidCategory = genderShort + (currentYear - kid.ageGroup);
                  kid.isSubscribed = this.kidsList.kidsAllreadySubscibred.some(regKid => regKid.kidId == kid.kidId);

                  if (kid.emailSprint) {
                    kid.email = kid.emailSprint;
                    kid.emailType = kid.emailTypeSprint;
                    return kid;
                  }
                  return kid;
                });
              }
            })
            .catch((err) => {
            })
            .then(() => {
              this.displayPageLoader = false;
              this.fixGridRefresh();

            });
        });

    },
    fixGridRefresh() {
      this.kidsList.kids = this.kidsList.kids.map((kid) => {
        kid.isKidSelected = this.kidsList.unsubscribeKids.some(selectedkKid => selectedkKid.kidId == kid.kidId)
        || this.kidsList.subscribeKids.some(selectedkKid => selectedkKid.kidId == kid.kidId);
        
        if(kid.isKidSelected == true) {
          kid.isKidSelected = true;
        }
    
        return kid;
      });
    },
  },
  computed: {
    hasKidMissingData() {
      return this.kidsList.subscribeKids.some(kid => !kid.emailType);
    }
  }
}
</script>

<style lang="scss">
#coachApplication {

  #kidsList {
  
    .custom-checkbox {
      color: var(--white);
      background-color: var(--primary);
      padding: .5rem;
      margin-bottom: 2px;
  
      &:hover {
        opacity: .8;
      }
  
      .custom-control-label {
        width: 100%;
        padding-left: 1.5rem;
  
        &:before {
          left: 0;
        }
        
        &:after {
          left: 0;
        }
  
        .selectionBox {
          width: 100%;
        }
      }
    }
  }
}
</style>